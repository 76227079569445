import React from "react";

export default function YoutubeEmbed(prop){
  return(
    <div className="YoutubeEmbedContainer">
        <iframe
          className="YoutubeIframe"
          src={`https://www.youtube.com/embed/${prop.embedId}?autoplay=0&rel=0showinfo=0`}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`${prop.videoTitle}`}
        />
    </div>
  )
}
