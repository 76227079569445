import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import Link from '../elements/link';
import {
  stripUrlParkPrefix,
  getTileImageFieldName,
  convertStringToId,
  testEnv,
  randomKey,
} from '../../functions/common';

export const TileImagesParagraph = ({ node, pageContextData }) => {
  const sectionTitle = node.field_title || '',
    includeTilesMap = node.field_include_tiles_map || false,
    tileGroups = node.relationships?.field_tile_image_groups;

  // Save a clone of the tileGroup object, as we need an immutable copy to loop
  // over when assessing group content, and another to build the nav that can
  // have empty groups removed without breaking .map() indexing.
  let cleanedTileGroups = structuredClone(tileGroups);

  // Stop if there's no data
  if (!node || !tileGroups || !cleanedTileGroups) {
    return <React.Fragment key={randomKey()}></React.Fragment>;
  }

  // Build the nav wrapper
  // Test if more than one group is used, add tabs if yes
  const buildNavWrapper = (dots) => {
    return tileGroups.length > 1 ? (
      <nav className="section__nav-wrapper t-center">
        <ul
          className="section__nav text-dots"
          id={
            `${sectionTitle && convertStringToId(sectionTitle)}-pager` ||
            `slider-${Math.ceil(Math.random() * 10000000)}`
          }
          style={{
            position: 'relative',
            left: '-50%',
          }}
        >
          {dots}
        </ul>
      </nav>
    ) : (
      <React.Fragment key={randomKey()}></React.Fragment>
    );
  };

  // Build the custom HTML for the SlickJS dots nav - we'll turn them into buttons

  // Create individual tiles
  const buildTile = (
    title: string,
    imgSrc: any,
    imgAlt: string = '',
    path: string,
    largeTile: boolean,
    index: number,
    tileType: string = '',
    status: boolean | true
  ) => {
    if (!title || !path) {
      testEnv().devMode
        ? console.log(
            `[ISSUE - Scripts]: Missing required data in buildTile() from 'Tile Images' paragraph type in paragraph ID ${node.id}, skipping...`
          )
        : null;
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }

    // Handle cases where status may not be defined
    if (typeof status !== 'boolean') {
      status = true;
    }

    // Check if image is valid, or fallback to a generic image
    // TODO: Replace this image with default placeholder image for each type

    const fallbackImg = '../../images/icon-file-generic.png',
      largeTileClass = largeTile ? ' tile--double-width' : '',
      //tileTypeClass is informational, for locating Drupal source
      // content more easily
      tileTypeClass = tileType ? ` tile--${tileType}` : '',
      titleClass = status ? 'tile__title' : 'tile__title admin-only';

    return title && path ? (
      <Link
        className={`tile${largeTileClass}${tileTypeClass}`}
        to={path}
        title={title}
        key={`${convertStringToId(title)}-${index}`}
      >
        <div className="tile__shim">
          <div className="tile__image-wrapper">
            {/* Allow graceful fallback for Gatsby not correctly saving a childImageSharp version */}
            {!imgSrc ? (
              <StaticImage
                src={fallbackImg}
                alt=""
                className="tile__image"
                loading="eager"
                width={170}
                height={170}
              />
            ) : typeof imgSrc === 'object' ? (
              <GatsbyImage image={imgSrc} alt={imgAlt} className="tile__image" loading="eager" />
            ) : (
              <img src={imgSrc} alt={imgAlt} className="tile__image" loading="eager" />
            )}
          </div>
        </div>
        <div className={titleClass}>
          {status ? '' : <span className="admin-only text-highlight">Not live:</span>}
          {title}
        </div>
      </Link>
    ) : (
      <React.Fragment key={randomKey()}></React.Fragment>
    );
  };

  const groupData = tileGroups.map((group, index: number) => {
    let groupType = group.internal?.type,
      items = [];

    // Cull empty paragraphs, which return empty objects
    if (!groupType) {
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }

    switch (groupType) {
      case 'paragraph__tile_images_content':
        // Content

        // Links to Nodes
        for (let item in group.relationships?.field_links) {
          let tileType = 'content',
            tile =
              group.relationships?.field_links[item]?.relationships?.field_content_links || null;

          // Check a title and URL exists, and cull any expired Events
          if (tile !== null && tile.title && tile.path.alias) {
            const pageUrl = stripUrlParkPrefix(tile.path.alias),
              mediaField = getTileImageFieldName(tile.internal.type),
              largeTile = group.relationships?.field_links[item].field_show_large_tile,
              imgSrc =
                largeTile === true
                  ? getImage(tile.relationships?.[mediaField]?.customLocalFieldMediaImageWide) ||
                    tile.relationships?.[mediaField]?.customLocalFieldMediaImageWide?.publicURL ||
                    null
                  : getImage(tile.relationships?.[mediaField]?.customLocalFieldMediaImage) ||
                    tile.relationships?.[mediaField]?.customLocalFieldMediaImage?.publicURL ||
                    null,
              imgAlt = tile.relationships?.[mediaField]?.field_media_image?.alt || '',
              tileStatus = tile.status;

            const renderedTile =
              buildTile(
                tile.title,
                imgSrc,
                imgAlt,
                pageUrl,
                largeTile,
                tile.id,
                tileType,
                tileStatus
              ) || null;

            renderedTile !== null && items.push(renderedTile);
          }
        }

        // Links to External URLs
        for (let item in group.relationships?.field_external_links) {
          let tileType = 'content-external',
            tile = group.relationships?.field_external_links[item] || null;

          // Check the external link exists
          if (tile !== null && tile.field_external_link.uri) {
            const text = tile.field_external_link.title || tile.field_external_link.uri,
              externalUrl = tile.field_external_link.uri,
              largeTile = tile.field_show_large_tile,
              imgSrc =
                largeTile === true
                  ? getImage(tile.relationships?.field_image?.customLocalFieldMediaImageWide) ||
                    tile.relationships?.field_image?.customLocalFieldMediaImageWide?.publicURL ||
                    null
                  : getImage(tile.relationships?.field_image?.customLocalFieldMediaImage) ||
                    tile.relationships?.field_image?.customLocalFieldMediaImage?.publicURL ||
                    null,
              imgAlt = tile.relationships?.field_image?.field_media_image?.alt;

            const renderedTile =
              buildTile(text, imgSrc, imgAlt, externalUrl, largeTile, tile.id, tileType, true) ||
              null;

            renderedTile !== null && items.push(renderedTile);
          }
        }

        // Links to Documents
        for (let item in group.relationships?.field_documents) {
          let tileType = 'content-document',
            tile = group.relationships?.field_documents[item] || null;

          // Check the document's file exists
          if (
            tile !== null &&
            tile.relationships?.field_file?.relationships?.field_media_file !== null
          ) {
            const text = tile.relationships?.field_file?.name,
              fileUrl = tile.relationships?.field_file?.customLocalFieldMediaFile?.publicURL,
              largeTile = tile.field_show_large_tile,
              imgSrc =
                largeTile === true
                  ? getImage(tile.relationships?.field_file?.customLocalFieldMediaImageWide) ||
                    tile.relationships?.field_file?.customLocalFieldMediaImageWide?.publicURL ||
                    null
                  : getImage(tile.relationships?.field_file?.customLocalFieldMediaImage) ||
                    tile.relationships?.field_file?.customLocalFieldMediaImage?.publicURL ||
                    null,
              imgAlt = tile.relationships?.field_file?.field_media_image?.alt,
              fileStatus = tile.status;

            const renderedTile =
              buildTile(
                `${text}`,
                imgSrc,
                imgAlt,
                fileUrl,
                largeTile,
                tile.id,
                tileType,
                fileStatus
              ) || null;

            renderedTile !== null && items.push(renderedTile);
          }
        }
        break;

      case 'paragraph__tile_images_collection':
        // Collection

        for (let item in group.relationships?.field_collection?.relationships) {
          let collectionData = group.relationships?.field_collection?.relationships[item];

          // The Collection items could originate from many content types, so to avoid
          // calling them all we just cull anything that is empty.
          if (collectionData && collectionData.length > 0) {
            for (let collectionItem in collectionData) {
              collectionItem =
                typeof collectionData[collectionItem] === 'object'
                  ? collectionData[collectionItem]
                  : null;
              const tileType = 'collection';

              // Check a title and URL exists, and cull any expired Events
              if (collectionItem && collectionItem.title && collectionItem.path?.alias) {
                const text = collectionItem.title,
                  pageUrl = stripUrlParkPrefix(collectionItem.path?.alias),
                  mediaField = getTileImageFieldName(collectionItem.internal?.type),
                  // Force square tiles
                  largeTile = false,
                  imgSrc =
                    getImage(
                      collectionItem.relationships?.[mediaField]?.customLocalFieldMediaImage
                    ) ||
                    collectionItem.relationships?.[mediaField]?.customLocalFieldMediaImage
                      ?.publicURL ||
                    null,
                  imgAlt =
                    collectionItem.relationships?.[mediaField]?.field_media_image?.alt || null,
                  tileStatus = collectionItem.status;

                const renderedTile =
                  buildTile(
                    text,
                    imgSrc,
                    imgAlt,
                    pageUrl,
                    largeTile,
                    collectionItem.id,
                    tileType,
                    tileStatus
                  ) || null;

                renderedTile !== null && items.push(renderedTile);
              }
            }
          }
        }
        break;

      case 'paragraph__tile_images_group_view':
        // Views
        //! NOTE: Filtering results should be done in the Drupal View, not here
        // If no viewUrl exists, the View was empty and never created in
        // GraphQL, so skip it
        if (group.relatedCustomDrupalView?.viewUrl !== null) {
          for (let item in group.relatedCustomDrupalView?.viewChildren) {
            item =
              typeof group.relatedCustomDrupalView?.viewChildren[item] === 'object'
                ? group.relatedCustomDrupalView?.viewChildren[item]
                : null;

            if (item && item.hasOwnProperty('id')) {
              const text = item.title || null,
                pageUrl = stripUrlParkPrefix(item?.path?.alias) || null,
                // Force square tiles for View items
                largeTile = false,
                tileType = 'view',
                imgMediaType = item.customMediaTileImage
                  ? 'customMediaTileImage'
                  : 'customMediaInlineImage',
                imgSrc =
                  getImage(
                    item?.[imgMediaType]?.relationships?.field_media_image?.relationships
                      ?.media__tile_image[0]?.customLocalFieldMediaImage
                  ) ||
                  item?.[imgMediaType]?.relationships?.field_media_image?.relationships
                    ?.media__tile_image[0]?.customLocalFieldMediaImage?.publicURL ||
                  null,
                imgAlt = item?.[imgMediaType]?.field_media_image?.alt || null,
                tileStatus = item.status;

              const renderedTile =
                buildTile(
                  text,
                  imgSrc,
                  imgAlt,
                  pageUrl,
                  largeTile,
                  item.id,
                  tileType,
                  tileStatus
                ) || null;

              renderedTile !== null && items.push(renderedTile);
            }
          }
        }
        break;

      default:
        testEnv().devMode
          ? console.warn(
              `[ISSUE - Scripts]: Tile Images groupType ${groupType} not identified, skipping...`
            )
          : null;
        return null;
    }

    // Cull empty groups from the cleanedTileGroups array, so we have a 1:1 copy
    // for building the nav
    if (items.length === 0) {
      cleanedTileGroups[index] = null;
    }

    // Finally, return a Slide for each set of items, and provide a random key
    return items.length > 0 ? (
      <div className="content-carousel__slide" key={`slide-group-${index}`}>
        <div className="tile-wrapper clearfix">{items}</div>
      </div>
    ) : (
      <React.Fragment key={`${node.index}-${node.info}`}></React.Fragment>
    );
  });

  // Full React Slick settings list:
  // https://react-slick.neostack.com/docs/api
  const mainSliderSettings = {
    accessibility: true,
    adaptiveHeight: true,
    arrows: false,
    autoplay: false,
    dots: true,
    // dotsAboveSlider requires ./patch/react-slick+0.28.1.patch
    // The MR to include this was denied as they are trying to keep feature
    // parity with Slick.js: https://github.com/akiran/react-slick/issues/2300
    dotsAboveSlider: true,
    fade: true,
    className: 'slick-tiles',
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    appendDots: (dots) => buildNavWrapper(dots),

    // Build the slider nav from the cloned array, as the original gets mutated
    // while inside it's own .map() and messes up the indexes. This leads to
    // misaligned headings for slides that have been culled.
    // The cleanedTileGroups array should contain 'null' values for groups with
    // no tiles to show, and therefore be culled here.
    customPaging: (i) =>
      cleanedTileGroups[i] && cleanedTileGroups[i].field_title ? (
        <div
          className="section__nav-item"
          key={`${sectionTitle && convertStringToId(sectionTitle)}-pager-${i}`}
        >
          <Link data-slide-index={i} to="#">
            {cleanedTileGroups[i].field_title}
          </Link>
        </div>
      ) : (
        <React.Fragment key={node.id}></React.Fragment>
      ),
  };

  // HTML component structure

  return groupData.length > 0 ? (
    <div
      className="paragraph_tile_images section--highlight-background pt-large"
      data-view-change-display-view=""
      key={node.id}
    >
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--12">
            {sectionTitle && (
              <div className="section__heading-wrapper pb-large">
                <h2
                  id={convertStringToId(sectionTitle)}
                  className="section__heading t-center pb-small"
                >
                  {sectionTitle}
                </h2>
              </div>
            )}
            <Slider {...mainSliderSettings}>{groupData}</Slider>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={`${node.index}-${node.info}`}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphTileImages on paragraph__tile_images {
    id
    drupal_id
    internal {
      type
    }
    field_title
    field_show_all_tab
    field_include_tiles_map
    relationships {
      field_tile_image_groups {
        # Static content
        ...TileImagesContent
        # Collections
        ...TileImagesCollection
        # Views
        ...TileImagesView
      }
    }
  }
`;
