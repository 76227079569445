import React from 'react';
import { convertStringToId} from '../../functions/common.js';

export default function Iframe({ src, width, height, fullscreen }) {
  if (!src) {
    return null;
  }
  // Set default image sizes to match embedded Youtube video dimensions, unless fullscreen
  if (!width) { width = 800 }
  if (!height) { height = 564 }

  const sourceDomain = new URL(src).hostname,
    name = convertStringToId(src);

  // If fullscreen, use style attr to force the size to fit the browser window
  // Note some content will resize within this shape to retain aspect ratios e.g. youtube videos
  return fullscreen === true ? (
    <iframe
      src={src}
      width={width}
      height={height}
      name={name}
      title={`External content from ${sourceDomain}`}
      allowFullScreen
      style={{ width: 100 + 'vw', height: 100 + 'vh' }}
    />
  ) : (
    <iframe
      src={src}
      width={width}
      height={height}
      name={name}
      title={`External content from ${sourceDomain}`}
      allowFullScreen={false}
    />
  );
};
