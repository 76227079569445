import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { AccordionItem } from './accordion-item';
import { convertStringToId } from '../../functions/common';

export const AccordionContainerParagraph = (query) => {
  const items =
    query.node.relationships?.field_accordion_items.map((item, index) => {
      const titleId = convertStringToId(item.field_accordion_item_title?.value),
        [isOpen, setIsOpen] = useState(false);

      return item.field_accordion_item_title?.value && item.field_accordion_item_body?.processed ? (
        <AccordionItem
          key={item.id}
          id={titleId}
          parentTitle={item.field_accordion_item_title.value}
          contents={item.field_accordion_item_body.processed}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <React.Fragment key={item.id}></React.Fragment>
      );
    }) || null;

  return (
    <div className="paragraph_accordion_container section--highlight-background py-large">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div className="copy">
              {query.node.field_accordion_container_intro?.processed && (
                <div
                  className="pb-medium"
                  dangerouslySetInnerHTML={{
                    __html: query.node.field_accordion_container_intro.processed,
                  }}
                />
              )}
              <div className="accordion-list">{items}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphAccordionContainer on paragraph__accordion_container {
    id
    drupal_id
    internal {
      type
    }
    field_accordion_container_intro {
      processed
    }
    relationships {
      field_accordion_items {
        id
        field_accordion_item_title {
          value
        }
        field_accordion_item_body {
          processed
        }
      }
    }
  }
`;
