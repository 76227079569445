import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertStringToId } from '../../functions/common';

export const PeopleParagraph = ({ node }) => {
  const people = node.relationships.field_person.map((person, index: Number) => {
    const name = person.name,
      introduction = person.field_person_introduction?.processed || null,
      body = person.body?.processed || null,
      // TODO: Add placeholder image
      imageData =
        getImage(person.relationships?.field_person_profile_image?.customLocalFieldMediaImage4) ||
        person.relationships?.field_person_profile_image?.customLocalFieldMediaImage4?.publicURL ||
        null,
      width = imageData?.width,
      height = imageData?.height;

    return name ? (
      <div
        className="text-tile text-tile--large grid-wrapper clearfix"
        id={convertStringToId(name)}
        key={`${convertStringToId(name)}-${index}`}
      >
        {imageData && (
          <div className="grid-item ph-grid-col--4">
            {typeof imageData === 'object' ? (
              <GatsbyImage image={imageData} alt={name} className="text-tile__image" />
            ) : (
              <img
                src={imageData}
                alt={name}
                className="text-tile__image"
                width={width}
                height={height}
              />
            )}
          </div>
        )}
        <div className={`${imageData ? 'text-tile__text-wrapper' : ''} copy`}>
          <h3 className="text-tile__title">{name}</h3>
          {introduction ? (
            <div className="text-tile__body" dangerouslySetInnerHTML={{ __html: introduction }} />
          ) : (
            <div className="text-tile__body"></div>
          )}
          {body ? (
            <div className="text-tile__body" dangerouslySetInnerHTML={{ __html: body }} />
          ) : (
            <div className="text-tile__body"></div>
          )}
        </div>
      </div>
    ) : (
      <React.Fragment key={`${node.id}-${index}`}></React.Fragment>
    );
  });

  return people ? (
    <div className="mb-medium paragraph_people" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 copy">
            {people}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphPeople on paragraph__people {
    id
    drupal_id
    internal {
      type
    }
    relationships {
      field_person {
        drupal_id
        body {
          processed
        }
        field_person_introduction {
          processed
        }
        name: title
        relationships {
          field_person_profile_image {
            ...MediaDataProfileImage
          }
        }
      }
    }
  }
`;
