import React from 'react';
import { graphql } from 'gatsby';
import { DevAids } from '../dev-aids';
import Link from '../elements/link';
import {
  isDummyOrTestContent,
  showTestContent,
  convertStringToId,
  currentPageMatchesUrlPattern,
  randomKey,
  testEnv,
} from '../../functions/common.js';

export const MailChimpOptInFormBlocks = ({ nodes, pagePath }) => {
  if (nodes === null) return <React.Fragment key={randomKey()}></React.Fragment>;

  const forms = nodes.nodes.map((form, index: number) => {
    // Filter out disabled blocks and dummy content
    if ((isDummyOrTestContent(form.info) && !showTestContent) || !form?.field_enabled) {
      return <React.Fragment key={form.id}></React.Fragment>;
    }

    const mailchimpForm = form?.relationships?.field_mailchimp_form_paragraph,
      identifier = mailchimpForm?.field_form_title || form.drupal_id,
      formTitle = mailchimpForm?.field_form_title || '',
      formId = `${convertStringToId(identifier)}-form`,
      formCheckBoxId = `${convertStringToId(identifier)}-checkbox`,
      formConsentId = `${convertStringToId(identifier)}-consent`,
      includeUrls = form?.field_include_or_exclude_urls,
      urlPattern = form?.field_url_pattern || [],
      formText = mailchimpForm?.field_form_text?.processed || '',
      formAction = mailchimpForm?.field_action ? mailchimpForm.field_action : '',
      botKey = mailchimpForm.field_bot_key || '',
      buttonText = mailchimpForm.field_button_text || '',
      photo1Src =
        mailchimpForm?.relationships?.field_photo_1?.customLocalFieldMediaImage?.publicURL || null,
      photo1Alt = mailchimpForm?.relationships?.field_photo_1?.field_media_image?.alt || '',
      // Lazy field names, watch for the trailing space in 'field_photo_2_
      photo2Src =
        mailchimpForm?.relationships?.field_photo_2_?.customLocalFieldMediaImage?.publicURL || null,
      photo2Alt = mailchimpForm?.relationships?.field_photo_2_?.field_media_image?.alt || '',
      photo3Src =
        mailchimpForm?.relationships?.field_photo_3?.customLocalFieldMediaImage?.publicURL || null,
      photo3Alt = mailchimpForm?.relationships?.field_photo_3?.field_media_image?.alt || '',
      photo4Src =
        mailchimpForm?.relationships?.field_photo_4?.customLocalFieldMediaImage?.publicURL || null,
      photo4Alt = mailchimpForm?.relationships?.field_photo_4?.field_media_image?.alt || '',
      currentPage = '',
      [state, setState] = React.useState<{ selections: string[] }>({ selections: [] });

    let renderOnThisPage = currentPageMatchesUrlPattern(urlPattern, includeUrls, pagePath);

    const handleCheck = (key) => {
      let sel = state.selections;
      let find = sel.indexOf(key);
      if (find > -1) {
        sel.splice(find, 1);
      } else {
        sel.push(key);
      }
      setState({
        selections: sel,
      });
    };

    const subscribeButtonClick = (e) => {
      var formConsenttElement = document.getElementById(formConsentId.toString());
      var currentId = formCheckBoxId.toString();
      if (!state.selections.toString().includes(currentId)) {
        e.preventDefault();
        formConsenttElement?.classList.add('consent--alert');
      }
    };

    if (!photo1Src || !photo2Src || !photo3Src || !photo4Src) {
      testEnv().devMode
        ? console.warn(
            `No image found in Mailchimp Form Block ID ${form.drupal_id}.\nThis can mean you\'re trying to render an unpublished media item, or the source image does not have a matching File node in GrahpQL\n`
          )
        : null;
    }

    return mailchimpForm === null || !renderOnThisPage || botKey.length < 1 ? (
      <React.Fragment key={form.id}></React.Fragment>
    ) : (
      <React.Fragment key={form.id}>
        <DevAids node={form} />
        <div
          className="section--with-subscribe-block py-xlarge block_mailchimp_opt_in_form"
          id="control"
        >
          <div className="grid-wrapper">
            <div className="grid-row clearfix">
              <div className="grid-col grid-col--12">
                <div className="subscribe-block subscribe-block--large">
                  <div aria-hidden="true" className="subscribe-block__photographs">
                    {photo1Src && (
                      <img
                        src={photo1Src}
                        alt={photo1Alt}
                        loading="lazy"
                        className={'subscribe-block__photo subscribe-block__photo--1'}
                      />
                    )}
                    {photo2Src && (
                      <img
                        src={photo2Src}
                        alt={photo2Alt}
                        loading="lazy"
                        className={'subscribe-block__photo subscribe-block__photo--2'}
                      />
                    )}
                    {photo3Src && (
                      <img
                        src={photo3Src}
                        alt={photo3Alt}
                        loading="lazy"
                        className={'subscribe-block__photo subscribe-block__photo--3'}
                      />
                    )}
                    {photo4Src && (
                      <img
                        src={photo4Src}
                        alt={photo4Alt}
                        loading="lazy"
                        className={'subscribe-block__photo subscribe-block__photo--4'}
                      />
                    )}
                  </div>
                  <div className="subscribe-block__inner">
                    <span className="subscribe-block__mail-icon" aria-hidden="true">
                      <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none">
                          <ellipse cx="25" cy="25" rx="25" ry="25" />
                          <path
                            d="M28.217 28.469L36 22.457v11.034a.774.774 0 0 1-.688.767A71.75 71.75 0 0 1 24.999 35c-3.438 0-6.924-.247-10.337-.742a.773.773 0 0 1-.663-.767V22.457l7.783 6.012c.908.693 2.063.99 3.217.99s2.308-.297 3.217-.99zm-13.357-8.51c-.589-.569-.786-1.039-.859-1.311V17.51c0-.396.295-.717.663-.767a72.094 72.094 0 0 1 10.337-.742c3.462 0 6.924.247 10.313.742a.773.773 0 0 1 .688.767v1.138c-.074.272-.27.742-.859 1.311l-8.373 6.383c-.467.371-1.105.544-1.768.544s-1.301-.173-1.768-.544l-8.373-6.383z"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </span>
                    <h2 className="subscribe-block__heading">{formTitle}</h2>
                    <div dangerouslySetInnerHTML={{ __html: formText }} />
                    <form
                      action={formAction}
                      id={formId}
                      className="subscribe-block__form"
                      method="post"
                      noValidate
                      name="mc-embedded-subscribe-form"
                      target="_blank"
                    >
                      <input
                        className="subscribe-block__input"
                        name="EMAIL"
                        id="subscribe-form-email"
                        placeholder="Enter your email address..."
                        type="email"
                      />
                      <label htmlFor="subscribe-form-email" hidden={true}>
                        Enter your email address
                      </label>
                      <input name="SIGNUP_URL" type="hidden" value={currentPage} />
                      {/* <input name="FORM_TYPE" type="hidden" value={formType} /> */}
                      {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                      <div
                        className="hidden-key hide-visually"
                        style={{
                          position: 'absolute',
                          left: '-5000px',
                          height: '1px',
                          width: '1px',
                          overflow: 'hidden',
                          clip: 'rect(1px, 1px, 1px, 1px)',
                        }}
                      >
                        <input type="text" id={botKey} defaultValue="" tabIndex={-1} />
                        <label htmlFor={botKey}>Please leave this field empty</label>
                      </div>
                      <button
                        className="button button--small subscribe-block__button"
                        type="submit"
                        onClick={subscribeButtonClick}
                      >
                        {buttonText}
                      </button>
                      <hr />
                      <div className="consent" id={formConsentId}>
                        <input
                          type="checkbox"
                          id={formCheckBoxId.toString()}
                          className="consent__checkbox"
                          name="CONSENT"
                          onChange={() => handleCheck(formCheckBoxId.toString())}
                          checked={state.selections.includes(formCheckBoxId.toString())}
                        />
                        <label className="consent__label" htmlFor={formCheckBoxId.toString()}>
                          I have read the{' '}
                          <Link
                            to="https://parksaustralia.gov.au/privacy-notice/"
                            target="_blank"
                            data-proofer-ignore
                            rel="noopener noreferrer"
                          >
                            Privacy Notice
                          </Link>{' '}
                          and consent to my personal information being used and disclosed in
                          accordance with that notice.
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  return forms.length > 0 ? <>{forms}</> : <React.Fragment key={randomKey()}></React.Fragment>;
};

export const fragment = graphql`
  fragment MailChimpOptInFormBlockQuery on block_content__mailchimp_opt_in_formConnection {
    nodes {
      id
      drupal_id
      info
      field_enabled
      field_include_or_exclude_urls
      field_url_pattern
      internal {
        type
      }
      relationships {
        field_mailchimp_form_paragraph {
          field_action
          field_bot_key
          field_button_text
          field_form_title
          field_form_text {
            value
            processed
          }
          field_padding
          relationships {
            field_photo_1 {
              ...MediaDataContentImageThumbnail
            }
            field_photo_2_ {
              ...MediaDataContentImageThumbnail
            }
            field_photo_3 {
              ...MediaDataContentImageThumbnail
            }
            field_photo_4 {
              ...MediaDataContentImageThumbnail
            }
          }
        }
      }
    }
  }
`;
