import React from 'react';
import { graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent, randomKey } from '../../functions/common';
import { DevAids } from '../dev-aids';

export const SiteWideMessageBlock = ({ nodes, callingPark }) => {
  const blocks = nodes.nodes.map((item, index: Number) => {
    const targetPark = item?.field_site_for_block?.drupal_internal__target_id;
    const enabled = item?.field_enabled;
    const messageType = item?.field_message_type;
    const body = item?.body?.processed;
    const info = item?.info;

    return body &&
      enabled === true &&
      callingPark === targetPark &&
      (!isDummyOrTestContent(info) || (isDummyOrTestContent(info) && showTestContent)) ? (
      <React.Fragment key={`${index}-${info}-${randomKey()}`}>
        <DevAids node={item} />
        <div
          className={`callout-box ${messageType}-box block_site_wide_message`}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </React.Fragment>
    ) : (
      <React.Fragment key={`${index}-${info}-${randomKey()}`}></React.Fragment>
    );
  });
  return <>{blocks}</>;
};

export const fragment = graphql`
  fragment SiteWideMessageBlockQuery on block_content__site_wide_messageConnection {
    nodes {
      id
      drupal_id
      internal {
        type
      }
      field_site_for_block {
        drupal_internal__target_id
      }
      body {
        processed
      }
      info
      field_enabled
      field_message_type
    }
  }
`;
