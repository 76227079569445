import React from 'react';
import { graphql } from 'gatsby';
import Button from './button';
import {
  convertBytesToHumanReadableFileSize,
  getFileExtension,
  stripUrlParkPrefix,
  stripUrlEntityPrefix,
} from '../../functions/common.js';

export const ButtonsContainerParagraph = ({ node }) => {
  const alignment = `t-${node.field_align || 'left'}`;

  // If there are several choices to use for the URL, prioritise text URL over Media
  const whichPathToUse = (link, file) => {
    if (link) {
      return link;
    } else if (file) {
      return file;
    } else {
      return false;
    }
  };

  // Collect the buttons
  const buttons = node.relationships.field_button.map((button, index: Number) => {
    let path;

    let text = button.field_title;

    // TODO: Buttons allow unlimited links in a single button, but only access
    // TODO: the first. Why tf did I do that.
    const link = button.field_link[0] || null,
      fileMetadata = button.relationships?.field_file?.relationships?.field_media_file || null,
      fileLink =
        button.relationships?.field_file?.customLocalFieldMediaAudioFile?.publicURL ||
        button.relationships?.field_file?.customLocalFieldMediaFile?.publicURL,
      backgroundImage =
        button.relationships?.field_button_background_image?.customLocalFieldMediaImage7 || null,
      backgroundImageAltText =
        button.relationships?.field_button_background_image?.field_media_image_7?.alt || null;

    // If Button has no destination, skip it
    if ((!fileLink && (!link || link.length < 1)) || (fileLink && !fileMetadata)) {
      return <React.Fragment key={`${button.drupal_id}-${index}`} />;
    }

    const file_size = convertBytesToHumanReadableFileSize(fileMetadata?.filesize) || null,
      file_type = getFileExtension(fileMetadata?.filename) || null;

    // If Button has both a Media and a URL, decide which to use
    // TODO: link will be a string if we cull it to handle a single value
    if (fileLink && link !== null && link.length >= 1) {
      path = whichPathToUse(link.uri, fileLink);
    } else if (fileLink) {
      // If the button points to a file, include the extension and file size
      path = fileLink;
      file_type && file_size ? (text += ` (${file_type}, ${file_size})`) : text;
    } else {
      // If a link has a URI and a URI Alias, use the alias.
      // If not an alias, remove the Drupal prefix from the path
      link.uri_alias
        ? (path = stripUrlParkPrefix(link.uri_alias))
        : (path = stripUrlEntityPrefix(link.uri));
    }

    return (
      <Button
        title={text}
        url={path}
        style={button.field_style || null}
        backgroundImage={backgroundImage}
        backgroundImageAltText={backgroundImageAltText}
        key={`button-${path}-${index}`}
      />
    );
  });

  return (
    <div className="my-medium paragraph_buttons_container">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 intro-section">
            <div className={`copy ${alignment}`}>{buttons}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// If updating the Media types a Button Paragraph Type can use, update the
// fragment here to capture it, then update the logic above to handle the new possibilities:
// /admin/structure/paragraphs_type/buttons/fields/paragraph.buttons.field_file

// We collect all the child button data here, not in the button component itself.
// This saves checking the parent paragraph ID against the child button's parent ID.

export const fragment = graphql`
  fragment ParagraphButtonsContainer on paragraph__button_container {
    id
    drupal_id
    internal {
      type
    }
    field_align
    relationships {
      field_button {
        field_link {
          uri
          uri_alias
        }
        field_style
        field_title
        drupal_id
        relationships {
          field_button_background_image {
            ... on media__button_background_image {
              name
              drupal_id
              customLocalFieldMediaImage7 {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
              field_media_image_7 {
                alt
              }
              relationships {
                field_media_image_7 {
                  ...FileInfo
                }
              }
            }
          }
          field_file {
            ... on media__audio_file {
              name
              drupal_id
              customLocalFieldMediaAudioFile {
                publicURL
              }
              relationships {
                field_media_audio_file {
                  ...FileInfo
                }
              }
            }
            ... on media__document {
              name
              drupal_id
              customLocalFieldMediaFile {
                publicURL
              }
              relationships {
                field_media_file {
                  ...FileInfo
                }
              }
            }
          }
        }
      }
    }
  }
`;
