import React, { useRef, useEffect } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

function Fancybox(props) {
  const containerRef = useRef(null);
  // Set any default options here via props.options
  // https://fancyapps.com/fancybox/api/options/

  // * NOTE:
  // Setting the slideshow wrapper class via props.options.mainClass
  // causes CSS breaking overlap, so we set it manually below

  useEffect(() => {
    const container = containerRef.current,
      delegate = props.delegate || '[data-fancybox]',
      options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return (
    <div className="image-gallery" ref={containerRef}>
      {props.children}
    </div>
  );
}

export default Fancybox;
