import React from 'react';
import { graphql } from 'gatsby';
import Script from '../elements/script';

export const EmbedScriptParagraph = ({ node }) => {
  const url = node.field_script_src ? node.field_script_src : false;

  // Check if the script is set to load asynchronously or be deferred
  const loadMethod = () => {
    if (node.field_script_loading_method !== 'normal') {
      return node.field_script_loading_method;
    }
    // React ignores null props
    return null;
  };

  // Only render the script tag if a src URL is provided.
  // We include then layout wrappers in case the script inserts
  // content into the page at that point to prevent the layout breaking.
  return url ? (
    <div key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <Script src={url} props={loadMethod()} key={node.id} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphEmbedScript on paragraph__embed_script {
    id
    drupal_id
    internal {
      type
    }
    field_script_loading_method
    field_script_src
  }
`;
