import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const QuoteWithImageParagraph = ({ node }) => {
  const quote = node.field_body?.processed,
    attribution = node.field_contact?.processed,
    imageData =
      getImage(node.relationships?.field_image?.customLocalFieldMediaImageThumbnail) ||
      node.relationships?.field_image?.customLocalFieldMediaImageThumbnail?.publicURL ||
      null,
    imageAlt = node.relationships.field_image?.field_media_image?.alt,
    width = imageData?.width,
    height = imageData?.height;

  return (
    <div className="paragraph_quote_with_image" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div className="text-tile text-tile--large grid-wrapper clearfix">
              {imageData && (
                <div className="grid-item ph-grid-col--4">
                  {typeof imageData === 'object' ? (
                    <GatsbyImage image={imageData} alt={imageAlt} className="text-tile__image" />
                  ) : (
                    <img
                      src={imageData}
                      alt={imageAlt}
                      className="text-tile__image"
                      width={width}
                      height={height}
                    />
                  )}
                </div>
              )}
              <div className="text-tile__text-wrapper">
                <div className="copy">
                  <div className="text-tile__body" dangerouslySetInnerHTML={{ __html: quote }} />
                  <div className="text-tile__body t-right">
                    <div
                      className="text-emphasis"
                      dangerouslySetInnerHTML={{ __html: attribution }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphQuoteWithImage on paragraph__quote_with_image {
    id
    drupal_id
    internal {
      type
    }
    field_body {
      processed
    }
    field_contact {
      processed
    }
    relationships {
      field_image {
        ...MediaDataTileImage
      }
    }
  }
`;
