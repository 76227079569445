import React, { useState } from 'react';

export const AccordionItem = (props) => {
  const { id, parentTitle, contents, isOpen, setIsOpen } = props;

  const [isPressed, setIsPressed] = useState(false);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleMouseDown() {
    setIsPressed(true);
  }

  function handleMouseUp() {
    setIsPressed(false);
  }

  return (
    <div key={id} className="accordion-item pt-medium" id={id}>
      <button
        id={id}
        onClick={toggleDropdown}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        aria-expanded={`${isOpen ? 'true' : 'false'}`}
        aria-pressed={`${isPressed ? 'true' : 'false'}`}
        aria-haspopup="true"
        className="accordion-button"
      >
        {parentTitle}
      </button>
      {isOpen && (
        <div
          className="accordion-dropdown pt-small slide-toggle"
          dangerouslySetInnerHTML={{ __html: contents }}
        />
      )}
    </div>
  );
};
