import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Fancybox from '../elements/fancybox';
import Link from '../elements/link';
import { convertStringToId } from '../../functions/common';

export const GalleryGridParagraph = ({ node }) => {
  // TODO: Consider removing the galleryTitle and galleryBody fields in Drupal,
  // TODO: as we've split text content out into separate paragraphs in similar cases.
  const galleryTitle = node.field_title || null,
    galleryBody = node.field_body?.processed || null,
    gallerySourceImages =
      node.relationships?.field_image_gallery?.relationships.field_image_gallery_images || null,
    galleryId = () => {
      return galleryTitle !== null
        ? `gallery--${convertStringToId(galleryTitle)}`
        : `gallery--${convertStringToId(node.relationships?.field_image_gallery.name)}`;
    };

  const galleryImages = gallerySourceImages.map((image: any, index: number) => {
    const hiResImagePath = image?.customLocalFieldMediaImage?.publicURL || null,
      thumbnailImageData = getImage(image.localImageMedium),
      altText = image.field_media_image?.alt || '',
      caption = image.field_caption?.processed || null,
      attribution = image.field_attribution?.processed || null,
      // Generate a combined field for the image based on the caption and
      // attribution text, if any
      subtitle = () => {
        return caption !== null && attribution !== null
          ? `${caption}. Attribution: ${attribution}`
          : caption !== null
          ? caption
          : attribution !== null
          ? `Attribution: ${attribution}`
          : null;
      };

    // If the Gatsby image can't be accessed, go no further. We deliberately
    // don't expose the Drupal source image for security, as we don't want to
    // reveal the source domain
    if (
      !hiResImagePath ||
      !thumbnailImageData ||
      typeof thumbnailImageData !== 'object' ||
      !Object.entries(thumbnailImageData.images)
    ) {
      return <React.Fragment key={image.id}></React.Fragment>;
    }

    return image?.customLocalFieldMediaImage?.publicURL ? (
      <Link
        className="image-gallery__item"
        data-caption={subtitle()}
        data-type="image"
        data-fancybox={galleryId()}
        to={image.customLocalFieldMediaImage.publicURL}
        title={`Gallery image ${index + 1}`}
        // Grab the filename and append the index to generate a unique ID, as it's capped
        // to 80 characters and the filepaths can be too long, leading to duplicate IDs
        key={`${convertStringToId(image.relationships.field_media_image.uri.url)}-${index}`}
      >
        <GatsbyImage image={thumbnailImageData} alt={altText} loading="eager" />
      </Link>
    ) : (
      <React.Fragment key={image.id}></React.Fragment>
    );
  });

  return galleryImages.length > 0 ? (
    <div className="paragraph_gallery_grid pb-medium" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div className="copy">
              {galleryTitle && <h2 id={galleryId()}>{galleryTitle}</h2>}
              {galleryBody && <div dangerouslySetInnerHTML={{ __html: galleryBody }}></div>}
              <Fancybox options={{}}>{galleryImages}</Fancybox>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphGalleryGrid on paragraph__image_gallery_grid {
    id
    drupal_id
    internal {
      type
    }
    field_title
    field_body {
      processed
    }
    relationships {
      field_image_gallery {
        name
        relationships {
          field_image_gallery_images {
            ...MediaDataContentImage
          }
        }
      }
    }
  }
`;
