import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertStringToId, randomKey } from '../../functions/common';
import { deprecationHandler } from 'moment-timezone';

export const HeroImageSectionParagraph = ({ node }) => {
  const image = getImage(node.relationships?.field_background_image?.customLocalFieldMediaImage) || null,
    imageMetadata = node.field_background_image || null,
    hideCaption = node.field_hide_caption,
    imgAlt = node?.relationships?.field_background_image?.field_media_image?.alt || '',
    imageAlignmentHorizontal = node.field_image_alignment_horizontal,
    imageAlignmentVertical = node.field_image_alignment_vertical,
    parallaxEffect = node.field_image_parallax_effect,
    shadowGradientOverlay = node.field_shadow_gradient_overlay,
    textColourTheme = node.field_text_colour_theme,
    title = node.field_title || null,
    imageCaption = node.field_image_caption || null,
    mediaImageCaption =
      node?.relationships?.field_background_image?.field_caption?.processed || null;

  return image ? (
    parallaxEffect ? (
      <div
        className={`mb-xlarge paragraph_hero_image_section media-id_${imageMetadata.drupal_internal__target_id}`}
        id={title && convertStringToId(title)}
        key={node.id}
      >
        <div
          className="parallax"
          style={{
            backgroundImage: `url(${node.relationships?.field_background_image?.customLocalFieldMediaImage?.publicURL})`,
            backgroundPosition: `${imageAlignmentHorizontal} ${imageAlignmentVertical}`,
          }}
        >
          <div className={`${shadowGradientOverlay && 'hero-block__overlay-container'}`}></div>
          {title && (
            <h2 className={`parallax__title ${textColourTheme && 'high-contrast'}`}>{title}</h2>
          )}
          {!hideCaption && (
            <div
              className={`parallax__credit ${textColourTheme && 'high-contrast'}`}
              dangerouslySetInnerHTML={{ __html: imageCaption || mediaImageCaption || null }}
            ></div>
          )}
        </div>
      </div>
    ) : (
      <div
        className={`mb-xlarge paragraph_hero_image_section media-id_${imageMetadata.drupal_internal__target_id}`}
        id={title && convertStringToId(title)}
        key={node.id}
      >
        <div className="hero-block__slide-wrapper">
          <div className="hero-block__inner">
            <GatsbyImage className="hero-block__image" image={image} alt={imgAlt} />
            <div className={`${shadowGradientOverlay && 'hero-block__overlay-container'}`}>
              {title && (
                <h2 className={`hero-block__title ${textColourTheme && 'high-contrast'}`}>
                  {title}
                </h2>
              )}
              {!hideCaption && (
                <div
                  className={`hero-block__credit ${textColourTheme && 'high-contrast'}`}
                  dangerouslySetInnerHTML={{ __html: imageCaption || mediaImageCaption || null }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <React.Fragment key={randomKey()}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphHeroImageSection on paragraph__hero_image_section {
    id
    drupal_id
    internal {
      type
    }
    field_background_image {
      drupal_internal__target_id
    }
    field_hide_caption
    field_image_alignment_horizontal
    field_image_alignment_vertical
    field_image_caption
    field_image_parallax_effect
    field_shadow_gradient_overlay
    field_text_colour_theme
    field_title
    relationships {
      field_background_image {
        field_caption {
          processed
        }
        field_media_image {
          alt
        }
        customLocalFieldMediaImage {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
    }
  }
`;
