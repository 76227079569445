import React from 'react';
import Link from '../elements/link';
import { stripUrlParkPrefix } from '../../functions/common';

export default function Breadcrumbs(props) {
  if (!props.crumbs) {
    console.warn(`[ISSUE - Menus]: No props.crumbs provided to Breadcrumbs component, skipping...`);
    return <></>;
  }
  let crumbLinks = props.crumbs.map((item) => {
    return (
      <li className="page-header__breadcrumb" key={item.node.link.uri_alias}>
        <Link to={stripUrlParkPrefix(item.node.link.uri_alias)}>{item.node.title}</Link>
      </li>
    );
  });

  // Reverse the array, so it renders in the correct order
  crumbLinks.reverse();

  // Add homepage to top level may need to map name
  return <nav><ul className="page-header__breadcrumbs clearfix">{crumbLinks}</ul></nav>;
}
