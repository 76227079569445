import React from 'react';
import { graphql } from 'gatsby';
import { TileImagesParagraph } from '../paragraphs/tile-images';
import { DevAids } from '../dev-aids';
import { isDummyOrTestContent, showTestContent, currentPageMatchesUrlPattern } from '../../functions/common';

export const TileImagesSomePagesBlocks = ({ nodes, pagePath }) => {
  if (!nodes) return <></>;

  const park = process.env.GATSBY_PARK;

  // Loop through the blocks and render them in a stack
  const blocks = nodes.nodes.map((block, index: number) => {
    const targetPark = block?.field_site_for_block?.drupal_internal__target_id,
      enabled = block?.field_enabled,
      includeUrls = block?.field_include_or_exclude_urls,
      urlPattern = block?.field_url_pattern || [],
      body = block?.body?.processed,
      info = block?.info,
      tiles = block.relationships?.field_tile_image_groups || null;

    let renderOnThisPage = currentPageMatchesUrlPattern(urlPattern, includeUrls, pagePath);

    // If the block is not enabled, or the park is missing or doesn't match, or
    // the block is set to include only certain pages and but the URL list is
    // empty, don't render the block
    if (
      !targetPark ||
      targetPark !== park ||
      enabled === false ||
      (includeUrls === true && urlPattern.length < 1)
    ) {
      return <React.Fragment key={`${index}-${info}`}></React.Fragment>;
    }

    return body &&
      tiles &&
      renderOnThisPage &&
      (!isDummyOrTestContent(info) || (isDummyOrTestContent(info) && showTestContent)) ? (
      <div
        key={`${index}-${info}`}
        className="block_tile_images_some_pages section--highlight-background pt-large"
      >
        <DevAids node={block} />
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </div>
            </div>
          </div>
        </div>
        <TileImagesParagraph key={block.id} node={tiles} pageContextData={{}} />
      </div>
    ) : (
      <React.Fragment key={`${index}-${info}`}></React.Fragment>
    );
  });

  // Render the blocks
  return blocks.length > 0 ? (
    <>{blocks}</>
  ) : (
    <React.Fragment key={`${nodes.id}-${nodes.info}`}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment TileImagesSomePagesBlockQuery on block_content__tile_images_some_pagesConnection {
    nodes {
      id
      drupal_id
      info
      body {
        processed
      }
      internal {
        type
      }
      field_site_for_block {
        drupal_internal__target_id
      }
      field_enabled
      field_include_or_exclude_urls
      field_url_pattern
      relationships {
        field_tile_image_groups {
          ...ParagraphTileImages
        }
      }
    }
  }
`;
