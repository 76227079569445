import React from 'react';
import { graphql } from 'gatsby';

export const TextParagraph = ({ node }) => {
  return node.field_body?.processed ? (
    <div className="my-large paragraph_text" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div
              className="copy"
              dangerouslySetInnerHTML={{ __html: node.field_body?.processed }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphText on paragraph__text {
    id
    drupal_id
    internal {
      type
    }
    field_body {
      processed
    }
  }
`;
