import React from 'react';
import { graphql } from 'gatsby';
import YoutubeEmbed from '../video/YoutubeEmbed';

export const EmbedVideoParagraph = ({ node }) => {
  let embedVideoSection;
  let videoEmbedId;
  let videoTitle;
  if (node?.field_video_title?.processed !== null && node?.field_video_id !== null) {
    videoEmbedId = node?.field_video_id;
    videoTitle = node?.field_video_title?.processed;
    embedVideoSection = <YoutubeEmbed embedId={videoEmbedId} videoTitle={videoTitle} />;
    return (
      <div className="mb-medium paragraph_embed_video" key={node.id}>
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">{embedVideoSection}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <React.Fragment key={node.id}></React.Fragment>;
  }
};

export const fragment = graphql`
  fragment ParagraphEmbedVideo on paragraph__embed_video {
    id
    drupal_id
    internal {
      type
    }
    field_video_id
    field_video_title {
      processed
    }
  }
`;
