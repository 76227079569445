import React from 'react';
import Link from '../elements/link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// This file refers to the 'buttons' (plural) paragraph type in Drupal,
// just to add to confusion

export default function Button({
  title,
  url,
  style,
  backgroundImage = undefined,
  backgroundImageAltText = '',
  ...props
}) {
  if (!url) return <></>;

  // These class names should match the option's machine names given in Drupal:
  // /admin/structure/paragraphs_type/buttons/fields/paragraph.buttons.field_style/storage
  const classNames = {
      default: '',
      alternate: 'button--alternate',
      triangle: 'button--with-triangle',
      'alternate triangle': 'button--alternate button--with-triangle',
    },
    matchedStyles = style ? classNames[style] : '',
    bgImg = backgroundImage ? getImage(backgroundImage) : null;

  return bgImg ? (
    <Link to={url} className={`button button--image paragraph_button`}>
      <GatsbyImage alt={backgroundImageAltText} className="text-tile__image" image={bgImg} />
      {title && <span className="hide-visually">{title}</span>}
    </Link>
  ) : (
    <Link to={url} className={`button paragraph_button ${matchedStyles}`}>
      <span>{title ? title : url}</span>
    </Link>
  );
}
