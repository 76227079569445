import React from 'react';
import { graphql } from 'gatsby';

export const TableParagraph = ({ node }) => {
  return node.field_body?.processed ? (
    <div className="my-large paragraph_table" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--12">
            <div className="table-wrapper">
              <div className="table-wrapper__inner">
                <div className="copy">
                  <div dangerouslySetInnerHTML={{ __html: node.field_body?.processed }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphTable on paragraph__table {
    id
    drupal_id
    internal {
      type
    }
    field_body {
      processed
    }
  }
`;
