import React from 'react';

const Iframe = (props) => {
  const frameTitle = props.title;
  const frameId = props.id;

  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    let doc = frame.contentDocument;
    doc.open();
    doc.write(props.content);
    doc.close();
    frame.style.width = '100%';
    frame.style.height = '100%';
    frame.style.flexGrow = 1;
    frame.style.border = 'none';
    frame.style.margin = 0;
    frame.style.padding = 0;
    frame.style.minHeight = '500px';
    // frame.style.height =  `${frame.contentWindow.document.body.scrollHeight}px`;
  };

  return (
    <>
      <iframe src="about:blank" id={frameId} title={frameTitle} ref={writeHTML} />
    </>
  );
};
export default Iframe;
