import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { testEnv, roundNumberUpOrDown } from '../../functions/common';

export const FigureParagraph = ({ node }) => {
  const imageMetadata = node.relationships.field_image,
    targetSize = node.field_size,
    imageBySize = () => {
      // Get the appropriate Gatsby image version based on the selected size
      let img;
      switch (targetSize) {
        case 'small':
          img =
            getImage(node.relationships.field_image?.localImageSmall) ||
            node.relationships.field_image?.localImageSmall?.publicURL ||
            null;
          break;
        case 'medium':
          img =
            getImage(node.relationships.field_image?.localImageMedium) ||
            node.relationships.field_image?.localImageMedium?.publicURL ||
            null;
          break;
        // default to full-width
        default:
          img =
            getImage(node.relationships.field_image?.customLocalFieldMediaImage) ||
            node.relationships.field_image?.customLocalFieldMediaImage?.publicURL ||
            null;
      }
      return img;
    },
    image = imageBySize(),
    sourceWidth = node.relationships.field_image?.field_media_image?.width,
    sourceHeight = node.relationships.field_image?.field_media_image?.height,
    altText = `${imageMetadata?.field_media_image?.alt}. ${
      imageMetadata?.field_attribution?.processed || ''
    }`.trim(),
    figureText = node.field_figure_left_text?.processed,
    // Only use alignment if there is accompanying text
    alignClass = figureText
      ? node.field_align !== 'center'
        ? `figure-float-${node.field_align}`
        : 'figure-center'
      : '',
    hideCaption = node.field_hide_caption || false,
    caption = hideCaption
      ? null
      : node.field_caption?.processed || imageMetadata?.field_caption?.processed || null;

  // Prevent build errors if attempting to render an unpublished image
  if (!image || !image.width) {
    testEnv().devMode
      ? console.warn(
          `[ISSUE - Images]: No image found in Figure paragraph ID ${node.drupal_id}.\nThis can mean you\'re trying to render an unpublished media item, or the source image does not have a matching File node in GrahpQL\n`
        )
      : null;
  }

  // Determine which set of dimensions to use - image.x sourceX
  // If none are found, ignore width and height, and let the image sit naturally

  const dimensions = image
    ? image.width && image.height
      ? { width: image.width, height: image.height, size: targetSize }
      : sourceWidth
      ? { width: sourceWidth, height: sourceHeight, size: targetSize }
      : undefined
    : null;

  // TODO: Need to factor in portrait images, as this currently calculates based
  // on width alone.
  // ? Instead of basing the height on the width, do it the other way around for
  //? portrait images. This Should be doable by testing if the width/height
  //? by checking if the width / height ratio is less than or greater than 0.5.
  const scaleImageDimensions = (width, height, size) => {
    // Set the size options for the image
    const sizeOptions = {
      small: 200,
      medium: 400,
      'full-width': 800,
    };

    // If the size is not defined, or width/height is missing, return the
    // original image dimensions
    if (sizeOptions[size] === undefined || !width || !height)
      return {
        width: sourceWidth,
        height: sourceHeight,
        size: '',
      };

    if (width < sizeOptions[size]) {
      // If the image is smaller than the requested size, use the image's
      // dimensions because we don't want to upscale due to quality loss.
      return {
        width: width,
        height: height,
        size: size,
      };
    } else {
      // Set the size via the image 'width' attr, but use css for the height
      // We add both attributes to the image so it's valid html
      let calcWidth = sizeOptions[size],
        ratio = calcWidth / width,
        calcHeight = height * ratio;

      // If the new height isn't a whole number, round it up or down to
      // avoid possible distortion
      const roundedCalcHeight = roundNumberUpOrDown(calcHeight);

      return {
        width: calcWidth,
        height: roundedCalcHeight,
        size: size,
      };
    }
  };

  // Render the overriding caption, or the one saved with the Media item itself,
  // or nothing
  const renderCaption = () => {
    if (hideCaption) return;
    let renderCaption = caption;
    return renderCaption;
  };

  // Calculate the image dimensions based on the size selected in Drupal
  const scaledDimensions = dimensions
    ? scaleImageDimensions(dimensions.width, dimensions?.height, targetSize)
    : undefined;

  // As <GatsbyImage> doesn't accept width/height as props, we instead pass the data
  // in via the gatsbyImageData object's width and height properties
  if (typeof image === 'object' && scaledDimensions) {
    image.width = scaledDimensions.width;
    image.height = scaledDimensions.height;
  }

  return image ? (
    <div className={`${targetSize !== null && 'py-large'} paragraph_figure`} key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 intro-section copy">
            <figure className={`${alignClass} figure-${targetSize}`}>
              {/* Allow graceful fallback for Gatsby not correctly saving a childImageSharp version */}
              {typeof image === 'object' ? (
                <GatsbyImage image={image} alt={altText} loading="eager" />
              ) : scaledDimensions ? (
                <img
                  src={image}
                  alt={altText}
                  width={scaledDimensions.width}
                  height={scaledDimensions.height}
                  loading="eager"
                />
              ) : (
                // If no dimensions exist, let the image render at its natural width
                <img src={image} alt={altText} loading="eager" />
              )}

              {renderCaption() ? (
                scaledDimensions ? (
                  <figcaption
                    // Match the image width to stop sidescrolling with long captions
                    style={{ maxWidth: scaledDimensions.width + `px` }}
                    dangerouslySetInnerHTML={{ __html: renderCaption() }}
                  />
                ) : (
                  // If no dimensions exist, let the chips land where they may
                  <figcaption dangerouslySetInnerHTML={{ __html: renderCaption() }} />
                )
              ) : (
                ''
              )}
            </figure>

            {figureText && <aside dangerouslySetInnerHTML={{ __html: figureText }} />}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphFigure on paragraph__figure {
    id
    drupal_id
    internal {
      type
    }
    field_align
    field_hide_caption
    field_size
    field_caption {
      processed
    }
    field_figure_left_text {
      processed
    }
    relationships {
      field_image {
        ...MediaDataContentImage
      }
    }
  }
`;
