import React from 'react';
import { SocialMediaLinksBlock } from '../blocks/social-media-links';
import Breadcrumbs from '../structure/breadcrumbs';
import { randomKey } from '../../functions/common';

export default function PageTitleSection({ title, socialMediaBlock, breadcrumbs, summaryText }) {
  return title ? (
    <div className="pt-large pb-small">
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--12">
            <div className="page-header">
              <Breadcrumbs crumbs={breadcrumbs} />
              <div className="page-header__heading-wrapper clearfix">
                <h1 className="page-header__heading">{title}</h1>
                {socialMediaBlock ? (
                  <ul id="share" tabIndex={0} className="page-header__social-icons">
                    <SocialMediaLinksBlock
                      node={socialMediaBlock}
                      title={title}
                      pageURI={breadcrumbs[0].node?.link?.uri_alias}
                      summaryText={summaryText}
                    />
                  </ul>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={randomKey()}></React.Fragment>
  );
}
