import React from 'react';
import { graphql } from 'gatsby';
import { isDummyOrTestContent, showTestContent } from '../../functions/common';
import { DevAids } from '../dev-aids';

export const MessageSomePagesBlock = ({ nodeID, nodes, callingPark }) => {
  const blocks = nodes?.nodes.map((item, index: Number) => {
    const targetPark = item?.field_site_for_block?.drupal_internal__target_id;
    const enabled = item?.field_enabled;
    const allowedPagesObj = item?.field_show_on_pages;
    const messageType = item?.field_message_type;
    const body = item?.body?.processed;
    const info = item?.info;

    // Check if the Block is permitted to appear on the calling page
    //? It may be possible to do this with niftier GraphQL filtering
    const allowedOnThisPage = () => {
      for (let i = 0; i < allowedPagesObj.length; i++) {
        if (allowedPagesObj[i].drupal_internal__target_id === nodeID) {
          return true;
        }
      }
      return false;
    };

    return body &&
      // Compare the calling page's Park to the Block's target Park to ensure no
      // cross-site contamination. This should be done in the calling query, but
      // let's be sure
      enabled === true &&
      callingPark === targetPark &&
      allowedOnThisPage() &&
      (!isDummyOrTestContent(info) || (isDummyOrTestContent(info) && showTestContent)) ? (
      <React.Fragment key={`${index}-${info}`}>
        <DevAids node={item} />
        <div
          className={`callout-box ${messageType}-box block_message_some_pages`}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </React.Fragment>
    ) : (
      <React.Fragment key={`${index}-${info}`}></React.Fragment>
    );
  });
  return <>{blocks}</>;
};

export const fragment = graphql`
  fragment MessageSomePagesBlockQuery on block_content__message_some_pagesConnection {
    nodes {
      id
      drupal_id
      internal {
        type
      }
      info
      field_site_for_block {
        drupal_internal__target_id
      }
      body {
        processed
      }
      field_enabled
      field_message_type
      field_show_on_pages {
        drupal_internal__target_id
      }
    }
  }
`;
