import React from 'react';
import { graphql } from 'gatsby';
import Link from '../../components/elements/link';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import {
  getTileImageFieldName,
  stripUrlParkPrefix,
  randomKey,
  RawText,
  formatDateToAEST,
  testEnv,
  convertStringToId,
} from '../../functions/common';

export const FeedListParagraph = ({ node }) => {
  // feedStyle must align with the values in the corresponding Drupal Paragraph Type.
  // See /admin/structure/paragraphs_type/feed_list/fields/paragraph.feed_list.field_feed_style/storage
  const feedStyle = node.field_feed_style,
    field_hide_caption = node.field_hide_caption,
    feedTitle = node.field_title || node.relationships.field_web_content_collection?.title || null,
    maxItems = node.field_max_items || null,
    sortBy = node.field_sort_by || null;

  let feedItems = [];

  // TODO: Add sorting options e.g. none, title, updated date, event date etc.

  // Check which field is used - Content list, Collection or View, by testing
  // for the presence of child items. If more than one is encountered,
  // prioritise the first to last.
  const feedType = () => {
    let type;
    if (node.relationships.field_content[0]?.drupal_id) {
      type = 'content';
    } else if (node.relationships.field_web_content_collection?.drupal_id) {
      type = 'collection';
    } else if (node.relationships.field_content_view?.drupal_id) {
      type = 'view';
    } else {
      type = undefined;
    }
    return type;
  };

  // Build the feed list based on the Feed Style
  const buildFeedList = (
    drupalId: string,
    url: string,
    title: string,
    imageData: object | string,
    imageAlt: string,
    summaryText: string,
    readMoreText: string,
    datePublished: string | null,
    startDate: string | null,
    endDate: string | null,
    where: string,
    when: string | null,
    status: boolean | true
  ) => {
    if (!drupalId || !url || !title) {
      testEnv().devMode
        ? console.warn(
            `[ISSUE - Scripts]: Missing required data for list_links buildFeedList() from 'Feed List' paragraph type in paragraph ID ${node.drupal_id}`
          )
        : null;
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }

    // Handle cases where status may not be defined
    if (typeof status !== 'boolean') {
      status = true;
    }

    let hasImage = imageData ? true : false;
    const linkId = convertStringToId(url);
    const fallbackImg = '../../images/logo-parks-australia-stacked.png';
    // Prioritize the Date fields and fall back to 'When' if empty
    //? NOTE: an empty 'when' arg gets reassigned somehow to 'undefined' as a string, not sure why
    const dateRange = startDate
      ? startDate === endDate
        ? `${formatDateToAEST(startDate)}`
        : `${formatDateToAEST(startDate)} - ${formatDateToAEST(endDate)}`
      : typeof when === 'string' || when !== 'undefined'
      ? when
      : null;

    if (feedStyle === 'list_links') {
      return (
        <li key={drupalId}>
          <Link to={url}>{title}</Link>
        </li>
      );
    } else if (feedStyle === 'list_teasers') {
      // Merge any dates into the Summary text, if any
      summaryText = datePublished
        ? `<em>${formatDateToAEST(datePublished)}</em><br><br>${summaryText}`
        : dateRange && where
        ? `<em>${dateRange} - ${where}</em><br><br>${summaryText}`
        : dateRange && dateRange !== 'undefined'
        ? `<em>${dateRange}</em><br><br>${summaryText}`
        : summaryText;

      return (
        <div className="text-tile text-tile--large clearfix grid-wrapper" key={drupalId}>
          <div className="grid-item ph-grid-col--4">
            <Link to={url} tabindex="-1">
              {hasImage ? (
                typeof imageData === 'object' ? (
                  <GatsbyImage alt={imageAlt} className="text-tile__image" image={imageData} />
                ) : (
                  <img src={imageData} alt={imageAlt} className="text-tile__image" />
                )
              ) : (
                <StaticImage
                  src={fallbackImg}
                  alt="Parks Australia crest"
                  className="text-tile__image"
                  loading="eager"
                  width={170}
                  height={170}
                />
              )}
            </Link>
          </div>
          <div className="text-tile__text-wrapper ph-grid-col--7 grid-col--8 ">
            {/* Switch between a H3 and Div element, depending if the
        list has an exposed Title (H2) */}
            {!field_hide_caption && feedTitle ? (
              <h3 className={(hasImage && 'text-tile__title') || 'text-tile__title no-image'}>
                <Link to={url} id={linkId}>
                  {status === true ? (
                    ''
                  ) : (
                    <span className="admin-only text-highlight">Not live:</span>
                  )}
                  {title}
                </Link>
              </h3>
            ) : (
              <div className={(hasImage && 'text-tile__title') || 'text-tile__title no-image'}>
                <Link to={url} id={linkId}>
                  {title}
                </Link>
              </div>
            )}
            {summaryText && (
              <div className="text-tile__body" dangerouslySetInnerHTML={{ __html: summaryText }} />
            )}
            <div className="call-to-action text-tile--call-to-action call-to-action--right">
              <Link tabindex="-1" to={url} aria-labelledby={linkId}>
                {readMoreText && readMoreText.toLowerCase() === 'read more' ? (
                  <span>
                    {readMoreText}
                    <span className="hide-visually"> about {title}</span>
                  </span>
                ) : (
                  <span>{readMoreText}</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      testEnv().devMode
        ? console.error(`[ERROR - Scripts]: Feed style '${feedStyle}' not recognised in Paragraph ID ${node.id}. Check the permitted values in
      /admin/structure/paragraphs_type/feed_list/fields/paragraph.feed_list.field_feed_style/storage`)
        : null;
      return <React.Fragment key={drupalId}></React.Fragment>;
    }
  };

  // If no type is detected, assume the Feed List has no content and do nothing.
  if (feedType() === undefined) {
    return false;
  } else if (feedType() === 'content') {
    // Content items

    if (!node.relationships?.field_content) {
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }
    feedItems = node.relationships?.field_content?.map((item, index: number) => {
      if (!maxItems || (maxItems && index < maxItems)) {
        const referencedItem = item.relationships?.field_content_item,
          drupalId = referencedItem?.drupal_id,
          // Not all content will necessarily have an alias. If not, don't include a link
          path = stripUrlParkPrefix(referencedItem?.path?.alias) || null,
          title = referencedItem?.title,
          // Publication Date only applies to News Articles
          datePublished = referencedItem?.field_publication_date || null,
          summaryText =
            node.relationships?.field_content[index]?.field_override_summary_text ||
            referencedItem?.body?.summary ||
            referencedItem?.body?.processed ||
            null,
          readMore =
            node.relationships?.field_content[index]?.field_override_read_more_text || 'Read more',
          // This can be extended using a switch statement if more content types require more image fields
          mediaField = getTileImageFieldName(referencedItem?.internal.type),
          imageAlt = referencedItem?.relationships[mediaField]?.field_media_image?.alt || null,
          infoWidget = referencedItem?.relationships?.field_information_widget || {},
          where = () => {
            return infoWidget?.field_where ||
              (infoWidget.hasOwnProperty('field_location') &&
                infoWidget?.field_location?.length > 1)
              ? `${infoWidget?.field_location[0]} and other locations`
              : infoWidget.hasOwnProperty('field_location')
              ? infoWidget?.field_location[0]
              : null;
          },
          when = RawText(infoWidget?.when?.processed) || null,
          status = node.relationships?.field_content[index]?.status;

        let imageData =
            getImage(
              referencedItem?.relationships[mediaField]?.customLocalFieldMediaImageThumbnail
            ) ||
            referencedItem?.relationships[mediaField]?.customLocalFieldMediaImageThumbnail
              ?.publicURL ||
            null,
          // Event dates only
          startDate = referencedItem?.field_event_date?.value || null,
          endDate = referencedItem?.field_event_date?.end_value || null;

        startDate = startDate ? formatDateToAEST(startDate) : null;
        endDate = endDate ? formatDateToAEST(endDate) : null;

        return buildFeedList(
          drupalId,
          path,
          title,
          imageData,
          imageAlt,
          summaryText,
          readMore,
          datePublished,
          startDate,
          endDate,
          where(),
          when,
          status
        );
      }
    });
  } else if (feedType() === 'collection') {
    // Collections
    const collectionItems = node.relationships.field_web_content_collection?.relationships || null;
    // Drill down to content items, as we don't know which content types are used
    // in this Collection we must test the results.
    if (!collectionItems) {
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }
    for (let key in collectionItems) {
      if (collectionItems[key] !== null) {
        // TODO: Not tested on Collections containing multiple content types
        const mappedItems = collectionItems[key].map((item, index: number) => {
          if (!maxItems || (maxItems && index < maxItems)) {
            const drupalId = item.drupal_id,
              path = stripUrlParkPrefix(item.path?.alias) || null,
              title = item.title || null,
              // Publication Date only applies to News Articles
              datePublished = item.field_publication_date || null,
              summaryText = item.body?.summary || item.body?.processed || null,
              readMore = 'Read more',
              mediaField = getTileImageFieldName(item.internal.type),
              imageData =
                getImage(item?.relationships?.[mediaField]?.customLocalFieldMediaImageThumbnail) ||
                item?.relationships?.[mediaField]?.customLocalFieldMediaImageThumbnail.publicURL ||
                null,
              imageAlt = item.relationships[mediaField]?.field_media_image?.alt || null,
              when =
                RawText(item?.relationships?.field_information_widget?.when?.processed) || null,
              where = () => {
                return item?.relationships?.field_information_widget?.field_where ||
                  item?.relationships?.field_information_widget?.field_location?.length > 1
                  ? `${item?.relationships?.field_information_widget?.field_location[0]} and other locations`
                  : item?.relationships?.field_information_widget?.field_location[0] || null;
              },
              status = item?.status;

            // Event dates only
            let startDate = item.field_event_date?.value || null,
              endDate = item.field_event_date?.end_value || null;

            startDate = startDate ? formatDateToAEST(startDate) : null;
            endDate = endDate ? formatDateToAEST(endDate) : null;

            return buildFeedList(
              drupalId,
              path,
              title,
              imageData,
              imageAlt,
              summaryText,
              readMore,
              datePublished,
              startDate,
              endDate,
              where(),
              when,
              status
            );
          }
        });
        feedItems.push(mappedItems);
      }
    }
  } else if (feedType() === 'view') {
    // Views
    //! NOTE: Filtering results should be done in the Drupal View, not here

    const viewsData = node.relationships?.field_content_view?.relatedCustomDrupalView || null;

    if (!viewsData) {
      return <React.Fragment key={randomKey()}></React.Fragment>;
    }
    // Loop through the data and build the feed items
    feedItems = viewsData.viewChildren.map((item, index: number) => {
      //ld(item)
      if (!maxItems || (maxItems && index < maxItems)) {
        const drupalId = item.id,
          title = item.title || null,
          // Publication Date only applies to News Articles
          publicationDate = item.field_publication_date || null,
          readMore = item.field_read_more || 'Read more',
          text = item?.body?.summary || item?.body?.processed || null,
          path = stripUrlParkPrefix(item?.path?.alias || item?.path?.url?.uri || null),
          imgMediaType = item.customMediaTileImage
            ? 'customMediaTileImage'
            : 'customMediaInlineImage',
          // Views don't support field_where or field_location as these live in
          // linked paragraphs and therefore not exposed in JSON API
          where = '',
          when = RawText(item?.relationships?.field_information_widget?.when?.processed) || null,
          imageData =
            getImage(
              item?.[imgMediaType]?.relationships?.field_media_image?.relationships
                ?.media__tile_image[0]?.customLocalFieldMediaImageThumbnail
            ) ||
            item?.[imgMediaType]?.relationships?.field_media_image?.relationships
              ?.media__tile_image[0]?.customLocalFieldMediaImageThumbnail?.publicURL ||
            null,
          imageAltText = item?.[imgMediaType]?.field_media_image?.alt || null,
          status = item?.status;

        // Event dates only, noting date filtering is done in the View
        let startDate = item.field_event_date?.value
            ? formatDateToAEST(item.field_event_date?.value)
            : null,
          endDate = item.field_event_date?.end_value
            ? formatDateToAEST(item.field_event_date?.end_value)
            : null;

        return buildFeedList(
          drupalId,
          path,
          title,
          imageData,
          imageAltText,
          text,
          readMore,
          publicationDate,
          startDate,
          endDate,
          where,
          when,
          status
        );
      }
    });
  } else {
    testEnv().devMode
      ? console.warn('[ISSUE - Scripts]: Unknown Feed type, unable to create feed: ' + feedType())
      : null;
  }

  // Clear out any null items that failed to build for any reason
  feedItems = feedItems.filter((item) => item !== null);

  // Export the wrapper and feed items within
  return feedItems.length > 0 ? (
    <div className="paragraph_feed_list" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 copy">
            {!field_hide_caption && feedTitle && <h2 className="pt-large">{feedTitle}</h2>}
            {feedStyle === 'list_links' ? (
              <ul>{feedItems}</ul>
            ) : (
              <div className="pt-large">{feedItems}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};

export const fragment = graphql`
  fragment ParagraphFeedList on paragraph__feed_list {
    id
    drupal_id
    internal {
      type
    }
    field_title
    field_feed_style
    field_hide_caption
    field_max_items
    field_sort_by
    relationships {
      field_content {
        drupal_id
        field_override_read_more_text
        field_override_summary_text
        relationships {
          field_content_item {
            ...NodeDataEvent
            ...NodeDataNews
            ...NodeDataPage
            ...NodeDataPlace
            ...NodeDataPublication
            ...NodeDataRegion
            ...NodeDataTour
            ...NodeDataWildlife
          }
        }
      }
      field_web_content_collection {
        drupal_id
        title
        relationships {
          node__event {
            ...NodeDataEvent
          }
          node__page {
            ...NodeDataPage
          }
          node__place {
            ...NodeDataPlace
          }
          node__tour {
            ...NodeDataTour
          }
          node__wildlife {
            ...NodeDataWildlife
          }
        }
      }
      field_content_view {
        id
        drupal_id
        field_view
        relatedCustomDrupalView {
          ...CustomDataDrupalView
        }
      }
    }
  }
`;
