import React from 'react';
import { graphql } from 'gatsby';
import {
  convertBytesToHumanReadableFileSize,
  convertStringToId,
  randomKey,
} from '../../functions/common.js';
import Link from '../elements/link';

export const DownloadsListParagraph = ({ node }) => {
  let mediaItems = node.relationships?.field_download_media || null;

  // Sort downloads alphabetically, case insensitively
  mediaItems = mediaItems
    ? mediaItems.sort((a, b) => {
        const nameA = a.name ? a.name.toUpperCase() : null;
        const nameB = b.name ? b.name.toUpperCase() : null;
        if (!nameA || !nameB) {
          return nameA.localeCompare(nameB);
        } else {
          new Error('DownloadsListParagraph: Media item name is undefined');
          return null;
        }
      })
    : [];

  if (!mediaItems) return <React.Fragment key={randomKey()}></React.Fragment>;

  const list = mediaItems?.map((item, index) => {
    const fileType =
      Object.keys(item).filter((key) => key.startsWith('customLocalFieldMedia'))[0] || null;

    if (!item || fileType === null || item.name === undefined) {
      return null;
    }

    const size = (fileType && convertBytesToHumanReadableFileSize(item[fileType]?.size)) || null,
      ext = item[fileType]?.extension.toUpperCase() || null,
      metadata = size && ext ? ` (${ext}, ${size})` : '';

    return item[fileType]?.publicURL ? (
      <li key={`${node.id}-${index}`}>
        <Link
          to={item[fileType]?.publicURL}
          id={`dl-${convertStringToId(item.name)}`}
        >{`${item.name}${metadata}`}</Link>
      </li>
    ) : null;
  });

  if (!list || list.length <= 0) {
    return <React.Fragment key={node.id}></React.Fragment>;
  }

  return (
    <div className="paragraph_downloads_list section--highlight-background pt-large" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 copy">
            <h2 id="downloads">Download resources</h2>
            <ul>{list}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphDownloadsList on paragraph__downloads_list {
    id
    drupal_id
    internal {
      type
    }
    relationships {
      field_download_media {
        ... on media__audio_file {
          id
          name
          field_site {
            drupal_internal__target_id
          }
          customLocalFieldMediaAudioFile {
            extension
            publicURL
            size
          }
        }
        ... on media__document {
          id
          name
          field_site {
            drupal_internal__target_id
          }
          customLocalFieldMediaFile {
            extension
            publicURL
            size
          }
        }
        ... on media__inline_image {
          id
          name
          field_site {
            drupal_internal__target_id
          }
          customLocalFieldMediaImage {
            extension
            publicURL
            size
          }
        }
      }
    }
  }
`;
