import React from 'react';
import { graphql } from 'gatsby';
import Iframe from '../elements/iframe';

export const EmbedIframeParagraph = ({ node }) => {
  // Set default image sizes to match embedded Youtube video dimensions, unless fullscreen
  const fullscreen = node.field_fullscreen,
    contentUrl = node.field_external_content_url || null,
    width = fullscreen ? '100%' : '804',
    height = fullscreen ? '100vh' : '452';

  return node.field_fullscreen ? (
    <div className="mb-xlarge paragraph_embed_iframe" key={node.id}>
      <Iframe src={contentUrl} width={width} height={height} fullscreen={fullscreen} />
    </div>
  ) : (
    <div className="mb-medium paragraph_embed_iframe" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div className="copy">
              <div className="responsive-embed">
                <Iframe src={contentUrl} width={width} height={height} fullscreen={fullscreen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphEmbedIframe on paragraph__embed_iframe {
    id
    drupal_id
    internal {
      type
    }
    field_external_content_url
    field_fullscreen
  }
`;
