import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from '../elements/link';
import { convertStringToId } from '../../functions/common.js';

/*
 * This component is unique as it requires all other instances if itself on
 * a page to be available each time it is used
 *
 * NOTE: If the Section Heading Link paragraph type in Drupal is allowed to
 * be used on any additional Content Types, they will need to be added in here
 */

export const SectionHeadingLinksParagraph = ({ node }) => {
  const sectionTitle = convertStringToId(node.field_title),
    sectionTitleId = sectionTitle + '-section',
    sectionParentPageId = node.parent_id,
    allSections = useStaticQuery(graphql`
      query {
        allParagraphSectionHeadingLinks {
          nodes {
            id
            drupal_id
            internal {
              type
            }
            field_hide_section_link
            field_title
            relationships {
              node__place {
                id
                drupal_internal__nid
              }
              node__page {
                id
                drupal_internal__nid
              }
            }
          }
        }
      }
    `);

  const SectionItems = () => {
    return allSections.allParagraphSectionHeadingLinks.nodes.map((item) => {
      //! If the Section Link paragraph type is added to any other Content Type
      //! in Drupal, they must be added here
      const parentId = () => {
        if (item.relationships?.node__page) {
          return item.relationships?.node__page[0].drupal_internal__nid;
        }
        if (item.relationships?.node__place) {
          return item.relationships?.node__place[0].drupal_internal__nid;
        }
        return null;
      };

      // Only return the Section Link if the parent ID matches
      // the current page, as this gets us a list of all sibling
      // Section Headings on the page
      if (parentId() === parseInt(sectionParentPageId)) {sectionTitle
        const currentSectionLink =
          sectionTitle === convertStringToId(item.field_title) ? 'section__nav-item--active' : '';
        return (
          <li
            className={`section__nav-item ${currentSectionLink}`}
            key={item.id}
          >
            <Link to={`#${convertStringToId(item.field_title)}-section`}>{item.field_title}</Link>
          </li>
        );
      }
    });
  };

  // Give an empty div with the ID if the Section Link should be hidden
  return node.field_hide_section_link ? (
    <div id={sectionTitleId} key={node.id}></div>
  ) : (
    <div className="paragraph_section_heading_links py-xsmall" key={node.id}>
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div id={sectionTitleId} className="section__nav-wrapper t-center pb-large">
              <ul className="section__nav">
                <SectionItems />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphSectionHeadingLinks on paragraph__section_heading_links {
    id
    drupal_id
    internal {
      type
    }
    field_title
    field_hide_section_link
    parent_id
  }
`;
